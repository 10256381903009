<template>
  <v-dialog
    :value="true"
    max-width="700"
    @keydown.esc="cancel"
    @click:outside="cancel"
    scrollable
  >
    <v-card>
      <v-card-title>
        {{ $t('youCanSelectACustomDateRange') }}
      </v-card-title>
      <v-card-text class="pb-0">
        <v-container
          fluid
          class="pa-0"
        >
          <v-row>
            <v-col cols="6">
              <DashDatePicker
                :selected-date="startTimeInMillis"
                @dateChanged="setStartDateInMillis"
                :label="$t('dateFrom')"
              />
            </v-col>
            <v-col cols="6">
              <DashDatePicker
                :selected-date="endTimeInMillis"
                @dateChanged="setEndDateInMillis"
                :label="$t('dateTo')"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="red darken-1"
          text
          @click="cancel"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="confirm"
          :disabled="isDisabled"
        >
          {{ $t('confirm') }}
        </v-btn>
      </v-card-actions>
      <v-row
        v-if="false"
        class="ma-1"
      >
        <v-col
          cols="10"
          class="text-h6 pb-4"
        >
          {{ $t('youCanSelectACustomDateRange') }}
        </v-col>
        <v-col
          cols="12"
          class="text--black pb-2"
        >
          {{ $t('customDateFrom') }}
        </v-col>
        <v-col
          cols="6"
          class="pl-4 pr-4 pt-0"
        >
          <DashDatePicker
            :selected-date="startDateInMillis"
            @dateChanged="setStartDateInMillis"
            :label="$t('dateFrom')"
          />
        </v-col>
        <v-col cols="6">
          <TimeSelectorDialog
            :selected-time="startTimeOfDayInMillis"
            @timeOfDayChanged="setStartTimeOfDayInMillis"
            :label="$t('timeFrom')"
          />
        </v-col>
        <v-col
          cols="12"
          class="text--black pb-2"
        >
          {{ $t('customDateTo') }}
        </v-col>
        <v-col
          cols="6"
          class="pl-4 pr-4 pt-0"
        >
          <DashDatePicker
            :selected-date="endDateInMillis"
            @dateChanged="setEndDateInMillis"
            :label="$t('dateTo')"
          />
        </v-col>
        <v-col cols="6">
          <TimeSelectorDialog
            :selected-time="endTimeOfDayInMillis"
            @timeOfDayChanged="setEndTimeOfDayInMillis"
            :label="$t('timeTo')"
          />
        </v-col>
      </v-row>
      <v-row
        class="text-end"
        v-if="false"
      >
        <v-col cols="6">
          <v-btn
            color="red darken-1"
            text
            @click="cancel"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="confirm"
            :disabled="isDisabled"
          >
            {{ $t('confirm') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import DashDatePicker from "@/components/dash/DashDatePicker"
import TimeSelectorDialog from "@/components/common/TimeSelectorDialog"

export default {
	/**
	 * Takes in start and end time (in milliseconds) and splits each into two components: Date in YYYY/MM/DD and time of day in HH:mm.
	 * Returns the start time and end time (in milliseconds) as the sum of their components.
	 */
  name: "CustomDashDateRange",
  props: {
    collection: {
      type: Object,
      default: () => {}
    },
    startTimeInMillis: {
      type: Number,
      default: null
    },
    endTimeInMillis: {
      type: Number,
      default: null
    }
  },
  components: {
    DashDatePicker,
    TimeSelectorDialog
  },
  data() {
    return {
			oneDayInMillis: 86400000,
			startDateInMillis: null,
			startTimeOfDayInMillis: null,
			endDateInMillis: null,
			endTimeOfDayInMillis: null,
    }
  },
	beforeMount() {
		// this.startDateInMillis = this.dayFromMillis(this.startTimeInMillis)
		// this.startTimeOfDayInMillis = this.timeOfDayFromMillis(this.startTimeInMillis)
		// this.endDateInMillis = this.dayFromMillis(this.endTimeInMillis)
		// this.endTimeOfDayInMillis = this.timeOfDayFromMillis(this.endTimeInMillis)
	},
  methods: {
    cancel() {
      this.$emit('onCancel')
    },
    confirm() {
      this.$emit('onConfirm', {
        startTimeInMillis: this.startDateInMillis,
        endTimeInMillis: this.endDateInMillis
      })
    },
		dayFromMillis(time) {
			if( time !== null && typeof time !== 'undefined' ) {
				return time - this.timeOfDayFromMillis(time)
			}
			return Date.now() - this.timeOfDayFromMillis(time)
		},
		timeOfDayFromMillis(time) {
			if( time !== null && typeof time !== 'undefined' ) {
				return time % this.oneDayInMillis
			}
			return Date.now() % this.oneDayInMillis
		},
		setStartDateInMillis(dateInMillis) {
			this.startDateInMillis = dateInMillis
		},
		setStartTimeOfDayInMillis(dateInMillis) {
			this.startTimeOfDayInMillis = dateInMillis
		},
		setEndDateInMillis(dateInMillis) {
      let endTimeIncludingEndDate = new Date(dateInMillis) 
      endTimeIncludingEndDate.setDate(endTimeIncludingEndDate.getDate() + 1)
      endTimeIncludingEndDate.setSeconds(endTimeIncludingEndDate.getSeconds() - 1)

      this.endDateInMillis = endTimeIncludingEndDate.getTime()
		},
		setEndTimeOfDayInMillis(dateInMillis) {
			this.endTimeOfDayInMillis = dateInMillis
		}
  },
  computed: {
    isDisabled() {
      if (this.startTimeOfDayInMillis + this.startDateInMillis <= this.endTimeOfDayInMillis + this.endDateInMillis) {
				return false
			}
      return true
    },
  }
}
</script>

<style scoped>

</style>
